import tw from "tailwind-styled-components";
import styled from "styled-components";
import { NextImage } from "../../elements";

export const SimpleWrapper = tw.div`
  flex
  flex-col
  gap-4

  desktop:grid
  desktop:grid-cols-2
  desktop:gap-6
`;

export const SimpleImageMobile = tw(NextImage)`
  w-full
  h-40
  object-cover
  rounded-3xl

  desktop:hidden
`;

export const SimpleImageDesktop = tw(NextImage)`
  hidden
  h-[540px]
  object-cover
  rounded-3xl

  desktop:block

  ${({ $invert }) => $invert && "desktop:-order-1"}
`;

export const MessageWrapper = tw.div`
  flex
  flex-col
  justify-between
  items-center
  gap-8
  bg-gray-100
  rounded-2xl
  p-6 desktop:p-20

  desktop:rounded-3xl
`;

export const MessageTitle = tw.h3`
  text-center
  text-xl desktop:text-5xl
`;

export const MessageDescription = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;

  @media screen and (min-width: 1128px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: center;
  }

  p {
    margin: 0;
  }
`;
