import dynamic from "next/dynamic";
import { renderLineBreaks } from "../../../utils";
import EditorJSContent from "../../editor-js-content";
import { Section } from "../index";
import {
  SimpleWrapper,
  SimpleImageMobile,
  SimpleImageDesktop,
  MessageWrapper,
  MessageTitle,
  MessageDescription,
} from "./styles";

const Link = dynamic(() => import("../../elements/link/link"));

export const Simple = ({
  sectionTitle,
  sectionSubtitle,
  hideLineSeparation,
  image,
  subtitle,
  description,
  descriptionRichText,
  link,
  button,
  invertImagePosition,
  displaySetting
}) => {
  return (
    <Section
      title={sectionTitle}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      <SimpleWrapper>
        {image && <SimpleImageMobile media={image} alt={sectionTitle} />}
        <MessageWrapper>
          {subtitle && (
            <MessageTitle>{renderLineBreaks(subtitle)}</MessageTitle>
          )}
          {descriptionRichText ? (
            <MessageDescription>
              <EditorJSContent content={descriptionRichText} />
            </MessageDescription>
          ) : (
            description && (
              <MessageDescription>
                {renderLineBreaks(description)}
              </MessageDescription>
            )
          )}
          {link && <Link type="text" {...link} />}
          {button && <Link type="buttonPrimary" {...button} />}
        </MessageWrapper>
        {image && (
          <SimpleImageDesktop
            $invert={invertImagePosition}
            media={image}
            alt={sectionTitle}
          />
        )}
      </SimpleWrapper>
    </Section>
  );
};

export default Simple;
